import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
import Autosize from '@marcreichel/alpine-autosize';

import submitFormViaFetch from './submit_form_via_fetch';

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', () => {
  window.Alpine.magic('getStimulusInstance', () => (elementId, controllerName) => {
    return Stimulus.getControllerForElementAndIdentifier(
      document.getElementById(elementId),
      controllerName,
    )
  });

  window.Alpine.magic('submitFormViaFetch', () => (form, callback = () => {}) => {
    submitFormViaFetch(form, callback);
  });

  Alpine.plugin(Autosize);

  Alpine.start();
});
