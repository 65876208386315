const submitFormViaFetch = (form, callback = () => {}) => {
  fetch(form.action, {
    method: form.method,
    body: new FormData(form),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      return response.json();
    })
    .then((data) => {
      callback(true, data);
    })
    .catch((error) => {
      callback(false, error);
    });
};

export default submitFormViaFetch;
